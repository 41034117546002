<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <!-- FORM START -->
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" v-validate="'required'" name="name" label="Name" v-model="name"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('name')">{{errors.first('name')}}</span>
        </div>
      </div>
      <!-- FORM END -->
    </div>
    <div class="vx-col w-full flex">
      <vs-button @click="store" color="primary" icon-pack="feather" icon="icon-save">Save</vs-button>&nbsp;
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  props:{
    id: {
      type: String,
      default: null
    }
  },
  data(){
    return {
      name: "",
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'interiors/store',
      dispatchUpdate: 'interiors/update',
      dispatchShow: 'interiors/show'
    }),
    store(){
      this.$validator.validateAll().then(async res => {
        if(!res) return false;
        let payload = {
          id: this.id,
          name: this.name,
        }
        try {
          if (this.id) {
            let {data} = await this.dispatchUpdate(payload)
            this.$emit('on-success', data)            
          } else {
            let {data} = await this.dispatchStore(payload)
            this.$emit('on-success', data)
          }
        } catch (error) {
          if(error.data.message){
            this.$vs.notify({
              title: 'Oops!',
              text: error.data.message,
              color: 'danger'
            })
          } else {
            for(let err in error.data){
              this.$vs.notify({
                title: 'Oops!',
                text: error.data[err][0],
                color: 'danger'
              })
            }
          }
        }
      })
    },
    async getDetail(){
      let { data } = await this.dispatchShow(this.id)
      if(!data){
        this.$vs.notify({
          title: 'Oops!',
          text: `Data not found`,
          color: 'danger'
        })
        this.$router.push({name: 'interiors'})
      } else {
        this.name = data.name
      }
    }
  },
  mounted(){
    // console.log(this.ids)
    if (this.id) {
      this.getDetail()
    }
  }
}
</script>

<style>

</style>